export const referenceType = {
  UCI: 'UCI',
  ACT: 'AUTH_ACT',
  ENROLMENT: 'ENROLMENT',
  ENGAGEMENT: 'ENGAGEMENT',
  PROGRAM: 'PROGRAM',
  TASK: 'TASK',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};
