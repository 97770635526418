import { get } from 'lodash';
import { createAPIService } from './api.service';
import errors, { createAPIError, defaultAPIError } from './errors';
import { poller, delay } from './enrollPolling';
import { getUtm } from 'utils/utm';
import { mapValuesAndFields } from 'utils/form.utils';
import { referenceType } from 'common/models';
import { logApiError } from 'utils/bugsnagHelper';

// expose endpoints for less brittle tests
export const ECARD_ENDPOINT = '/card/fulfillment/ecard';
export const ENROLL_ENDPOINT = '/enrollments';
export const FULFILLMENT_ENDPOINT = '/card/fulfillment';
export const FIND_ENROLLMENTS_ENDPOINT = '/card/findEnrollments';

export const CHECK_UCI_ENDPOINT = uci => `/card/${uci}/enrollment`;

const service = createAPIService('/services/patient');

// endpoints
service.getECard = async (language, programId) => {
  const response = await service.post(
    ECARD_ENDPOINT,
    {
      language,
      program: programId,
      utm: getUtm(),
    },
    { responseType: 'blob' }
  );

  const filename = (response.headers['content-disposition'] || '').match(
    /filename="(.*)"/
  );
  return {
    filename: filename && filename.length ? filename[1] : undefined,
    data: response.data,
  };
};

service.enroll = async (data, pathMap, processData = true) => {
  const act = (
    (data.references || []).find(
      el => el.referenceType === referenceType.ACT
    ) || {}
  ).referenceId;
  const interceptor = service.interceptors.response.use(async resp => {
    await delay(2000);
    return poller(resp.data.processId, act, 1000, 2000);
  });
  try {
    const response = await service.post(
      ENROLL_ENDPOINT,
      processData ? mapValuesAndFields(data, pathMap) : data
    );
    return response.data;
  } catch (err) {
    logApiError(err, 'failed enrollment');
    throw defaultAPIError(err, errors.ENROLLMENT_FAILED);
  } finally {
    service.interceptors.response.eject(interceptor);
  }
};

service.checkUCI = async data => {
  try {
    const response = await service.post(
      CHECK_UCI_ENDPOINT(data.clientId || data.benefit.card.uci)
    );
    if (response.data.enrollmentExists) {
      throw createAPIError(errors.ENROLLMENT_EXISTS_FOR_CLIENTID);
    }
    return true;
  } catch (err) {
    switch (get(err, 'response.status', err.code)) {
      case 404:
      case 403:
        throw createAPIError(errors.UCI_INVALID);
      default:
        throw defaultAPIError(err);
    }
  }
};

service.fulfillment = async ({ preferredLanguage, programId, clientId }) => {
  try {
    const response = await service.post(FULFILLMENT_ENDPOINT, {
      language: preferredLanguage,
      uci: clientId,
      program: programId,
    });
    return response;
  } catch (err) {
    throw defaultAPIError(err);
  }
};

service.findEnrollments = async (data, configurations, params) => {
  try {
    const requestPayload = {
      programId:
        params.length > 0
          ? params
          : get(data, 'benefit.program.programId', null),
      dob: get(data, 'patient.person.dateOfBirth', null),
      firstName: get(data, 'patient.person.contactInfo.firstName', null),
      lastName: get(data, 'patient.person.contactInfo.lastName', null),
    };
    const response = await service.post(
      FIND_ENROLLMENTS_ENDPOINT,
      requestPayload
    );
    if (response.data && response.data[0].enrollmentUuid) {
      throw createAPIError(errors.ENROLLMENT_EXISTS_FOR_PATIENT);
    }
    return false;
  } catch (err) {
    switch (get(err, 'response.status', err.code)) {
      case 404:
        return false;
      default:
        throw defaultAPIError(err);
    }
  }
};

export default service;
