import Bugsnag from '@bugsnag/js';
import { createAPIService } from './api.service';
import errors, { createAPIError } from './errors';

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const service = createAPIService('/services/patient/processes/');

const pollRequest = async (processId, act) => {
  try {
    return await service.get(processId, {
      headers: {
        act,
      },
    });
  } catch (e) {
    return e;
  }
};

export const poller = async (
  processId,
  act,
  initialDelay,
  period,
  pollAttempts = 20
) => {
  await delay(initialDelay);
  let i = 0;
  while (i < pollAttempts) {
    const res = await pollRequest(processId, act);
    if (res.data && res.data.benefit) {
      return res;
    }
    if (res.data.exitStatus === 'FAILED' || !pollAttempts) {
      Bugsnag.leaveBreadcrumb('Enrolment failed due to', res);
    }
    let remainingAttempts = pollAttempts - i;
    Bugsnag.notify(
      `enrolment polling failed - ${remainingAttempts} retries remaining`
    );
    await delay(period);
    i += 1;
  }
  Bugsnag.notify(e => e.addMetadata(`No more attempts left...`));
  createAPIError(errors.ENROLLMENT_FAILED);
};
